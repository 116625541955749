import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);
