import { Classifier } from '@fleet/shared/dto/classifier';

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  CULTURE = 'CULTURE',
  TICKET_PAYMENT_DEADLINE_TYPE = 'TICKET_PAYMENT_DEADLINE_TYPE',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.CULTURE,
  ClassificationGroup.TICKET_PAYMENT_DEADLINE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}
