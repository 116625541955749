import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Loader, theme, ThemeProvider } from '@fleet/shared/mui';
import App from 'App';
import { Provider } from 'react-redux';
import { AuthProvider } from 'react-oidc-context';
import { alertProviderProps } from '@fleet/shared/components/Alert';
import { Provider as AlertProvider } from 'react-alert';
import { store } from 'store';
import authConf from 'authConf';
import 'i18n';
import 'index.scss';
import AxiosErrorsInterceptorWrapper from 'wrappers/AxiosErrorsInterceptorWrapper';
import { initializeSentry } from 'helpers/sentryConfig';

initializeSentry();

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider {...authConf}>
            <AlertProvider {...alertProviderProps}>
              <AxiosErrorsInterceptorWrapper>
                <App />
              </AxiosErrorsInterceptorWrapper>
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
